<template>
    <div>
        <div class="container-fluid ">
            <div class="container my-5 py-5">
                <div class="d-flex flex-column align-items-center">
                    <img class="col-8 col-lg-4 mb-5" :src="vector.url" alt="" />
                    <h1 class="col-12 col-lg-8 fw-bold text-center text-darkblue mt-4 mb-5">
                        TAX REFUND AND TAX AUDIT ASSISTANCE
                    </h1>
                    <div class="col-12 col-lg-8">
                        <h5 class="mb-3">Our service includes :</h5>
                        <ul>
                            <li v-for="(service, index) in services" :key="index" class="py-1">
                                {{ service }}
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<style scoped>
li {
    list-style: "—   ";
}
</style>
<script>
export default {
    data() {
        return {
            vector: {
                url: "https://firebasestorage.googleapis.com/v0/b/jnpaccounting-5fe5f.appspot.com/o/imgs%2Fvector%2Fdiscount.svg?alt=media&token=bb59c77b-df80-478a-a906-985786f1b5da"
            },
            services: [
                "Draft explanatory letter in English and Thai for requesting refund.",
                "Consulting on required supporting documents for requesting a refund.",
                "Translation of supporting documents from English to Thai.",
                "Submission of refund request forms to Revenue Department.",
                "Follow up of refund request with Revenue Department until decision.",
                "Representing and assisting in discussions with the Revenue Department.",
                "Correspondence by email, telephone and in person meetings when necessary."
            ]
        }
    }
}
</script>